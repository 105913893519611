<template>
  <div>
    <v-container id="regular-tables-view" fluid tag="section">
      <v-row justify="center">
        <!-- CRUD Referral -->
        <v-col cols="12" md="6">
          <v-card class="text-center mt-n4 mb-5" elevation="0">
            <v-row class="justify-center">
              <h1 class="text-h4 font-weight-medium mt-4 mb-5">
                {{ !workerReferral._id ? 'Tambah' : 'Edit' }} Referral
              </h1>
            </v-row>
            <v-form ref="workerReferralForm" v-model="workerReferralForm">
              <v-row class="justify-center">
                <v-col cols="11">
                  <v-text-field
                    v-model="workerReferral.title"
                    label="Kode Referral"
                    :rules="workerReferralRules.title"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row class="pb-7 px-7">
                <div v-if="workerReferral._id && isShowDelete">
                  <v-btn
                    class="mr-auto"
                    color="red darken-3 white--text"
                    rounded
                    :loading="loadingRemoveReferral"
                    @click="removeReferral"
                  >
                    Hapus
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div v-if="workerReferral._id">
                  <v-btn class="mr-3" color="grey white--text" rounded @click="resetReferral"> Kembali </v-btn>
                  <v-btn
                    color="yellow darken-4 white--text"
                    rounded
                    :loading="loadingEditReferral"
                    @click="editReferral"
                  >
                    Edit
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn color="blue darken-2 white--text" rounded :loading="loadingAddReferral" @click="addReferral">
                    Tambah
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <!-- Filter -->
        <v-col cols="12" md="6">
          <v-card class="text-center mt-n4" elevation="0">
            <v-card-text class="text-center">
              <h4 class="text-h4 mb-5 text-left text--primary font-weight-medium">Cari Referral</h4>
              <v-form
                ref="filterReferral"
                v-model="filterReferralValid"
                @submit.prevent="
                  () => {
                    page = 1
                    getReferral()
                  }
                "
              >
                <v-text-field v-model="filter.title" label="Kode Referral" outlined dense no-data-text="" />
                <div class="d-flex justify-center">
                  <v-checkbox v-model="filter.isUseDate" class="ma-0 pa-0" label="Filter tanggal dibuat"></v-checkbox>
                </div>
                <v-scroll-y-transition>
                  <v-col cols="12" v-if="filter.isUseDate" class="py-0">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <atoms-date-picker
                          label="Dari Tanggal"
                          :modal="from.modal"
                          :value="from.value"
                          @change="
                            (modal, value) => {
                              from.modal = modal
                              from.value = value
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <atoms-date-picker
                          label="Sampai Tanggal"
                          :modal="to.modal"
                          :value="to.value"
                          @change="
                            (modal, value) => {
                              to.modal = modal
                              to.value = value
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-scroll-y-transition>
                <v-btn type="submit" class="mr-0" color="primary" min-width="100" rounded :disabled="isLoading">
                  Cari
                </v-btn>
                <br />
                <v-btn class="mt-3" text x-small @click="filter = {}">
                  <v-icon left> mdi-refresh </v-icon>
                  Reset Filter
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Referral -->
      <v-card class="mt-4 pt-5 pb-3" elevation="0">
        <div class="cards mx-5">
          <p v-if="workerReferrals.length <= 0 && !isLoading" class="text-center mt-3">Kosong</p>
          <v-row v-else>
            <!-- simple table -->
            <v-col class="pt-0" cols="12">
              <v-simple-table class="text-caption">
                <thead>
                  <tr>
                    <th
                      v-for="item in headCols"
                      :key="item.text"
                      class="headCol text-left"
                      :style="{
                        position: 'relative',
                        'white-space': 'nowrap',
                      }"
                    >
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in workerReferrals" :key="item._id" class="py-2">
                    <td>{{ +idx + 1 }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.createdByName }}</td>
                    <td style="white-space: nowrap">
                      {{ item._createdDate && $moment(item._createdDate).format('DD-MM-YYYY') }}
                    </td>
                    <td>{{ item.updatedByName }}</td>
                    <td style="white-space: nowrap">
                      {{ item._updatedDate && $moment(item._updatedDate).format('DD-MM-YYYY') }}
                    </td>
                    <td>
                      <div>
                        <v-btn icon color="yellow darken-4" @click.stop="selectReferral(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
        <div v-if="isLoading" class="d-flex justify-center">
          <v-progress-circular class="my-4" indeterminate color="primary" size="30" />
        </div>
        <div class="text-center pt-3 pb-1">
          <v-pagination v-model="page" :length="pagesLength" :total-visible="7" />
        </div>
      </v-card>
      <div class="py-3" />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Referral',
  components: {},
  data() {
    return {
      // form crud
      clients: [],
      managers: [],
      workerReferral: {
        title: '',
      },
      workerReferralRules: {
        title: [(v) => !!v || 'Harap diisi'],
      },
      workerReferralForm: false,
      // client card
      clientCard: {},
      // Filter
      filter: {
        isUseDate: false,
      },
      filterReferralValid: false,
      // Table
      headCols: [
        {
          text: 'No.',
        },
        {
          text: 'Referral',
        },
        {
          text: 'Dibuat oleh',
        },
        {
          text: 'Tanggal dibuat',
        },
        {
          text: 'Diedit oleh',
        },
        {
          text: 'Tanggal diedit',
        },
        {
          text: 'Aksi',
        },
      ],
      sort: {
        lastContacted: -1,
      },
      isSort: false,
      workerReferrals: [],
      sum: {},
      workerReferralsLength: '',
      isLoading: true,
      page: 1,
      limit: 10,
      pagesLength: 1,
      // Item
      selectedId: '',
      loadingGetManagers: false,
      loadingGetWorkers: false,
      loadingGetClients: false,
      loadingAddReferral: false,
      loadingEditReferral: false,
      loadingRemoveReferral: false,
      isLoadingApprove: false,
      isLoadingReject: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  computed: {
    isShowDelete() {
      const roles = this.$store.state.user?.roles || []
      return roles.some((val) => val === 'Super Admin')
    },
    marginPercentage() {
      const upah = this.$this.$convertRupiahtoNumber(this.workerReferral?.upah)
      const penawaran = this.$this.$convertRupiahtoNumber(this.workerReferral?.penawaran)
      let marginPercentage = 0
      if (penawaran > 0) {
        marginPercentage = (penawaran - upah) / penawaran
      }
      return marginPercentage
    },
  },
  watch: {
    async page() {
      await this.getReferral()
    },
    'filter.isUseDate': function (isTrue) {
      if (!isTrue) {
        this.filter.dateStart = null
        this.filter.dateEnd = null
      }
    },
  },
  async mounted() {
    // get Referral data
    await this.getReferral()
  },
  methods: {
    async addReferral() {
      this.$refs.workerReferralForm.validate()
      if (!this.workerReferralForm) {
        window.scrollTo({
          top: this.$refs.workerReferralForm.$el.offsetTop,
          behavior: 'smooth',
        })
        return
      }
      const userId = this.$store.state.user?._id
      this.loadingAddReferral = true
      try {
        const workerReferral = {
          _id: this.$uuid.v4(),
          ...this.workerReferral,
          createdBy: userId,
        }
        const result = await this.$api.post(`/WorkerReferrals/create`, workerReferral).then((data) => data?.result)
        if (result) {
          this.resetReferral()
          this.getReferral()
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.loadingAddReferral = false
    },
    async editReferral() {
      this.$refs.workerReferralForm.validate()
      if (!this.workerReferralForm) {
        return
      }
      const userId = this.$store.state.user?._id
      this.loadingEditReferral = true
      try {
        const workerReferral = {
          ...this.workerReferral,
          updatedBy: userId,
        }
        const result = await this.$api.patch(`/WorkerReferrals/update`, workerReferral).then((data) => data?.result)
        if (result) {
          this.resetReferral()
          this.getReferral()
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.loadingEditReferral = false
    },
    async removeReferral() {
      const isConfirmed = confirm('Yakin ingin hapus referral ini?')
      if (!isConfirmed) return
      this.loadingRemoveReferral = true
      try {
        const result = await this.$api
          .delete(`/WorkerReferrals/remove`, {
            params: {
              _id: this.workerReferral._id,
            },
          })
          .then((data) => data?.result)
        if (result) {
          this.resetReferral()
          this.getReferral()
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.loadingRemoveReferral = false
    },
    resetReferral() {
      this.workerReferral = {}
      this.$refs.workerReferralForm.resetValidation()
    },
    selectReferral(item) {
      this.workerReferral = Object.assign({}, item)
      if (item?.manager?._id) {
        this.managers = [item.manager]
        this.workerReferral.managerId = item.manager._id
      }
      window.scroll({
        top: this.$refs.workerReferralForm.$el.offsetTop,
        behavior: 'smooth',
      })
    },
    async getReferral() {
      this.$refs.filterReferral.validate()
      if (!this.filterReferralValid) return
      this.isLoading = true
      try {
        this.workerReferrals = []
        const title = this.filter?.title
          ? {
              $regex: this.filter.title || '',
              $options: 'i',
            }
          : undefined
        const filterMongo = {
          title,
          pipeline: [
            {
              $lookup: {
                from: 'Users',
                localField: 'createdBy',
                foreignField: '_id',
                as: 'createdByData',
              },
            },
            {
              $unwind: {
                path: '$createdByData',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'updatedBy',
                foreignField: '_id',
                as: 'updatedByData',
              },
            },
            {
              $unwind: {
                path: '$updatedByData',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $project: {
                title: 1,
                createdByName: '$createdByData.nickname',
                updatedByName: '$updatedByData.nickname',
                _updatedDate: 1,
                _createdDate: 1,
              },
            },
            {
              $sort: {
                _createdDate: -1,
              },
            },
          ],
        }
        // filter date
        if (this.filter?.isUseDate) {
          filterMongo._createdDate = {
            $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
            $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
          }
        }
        const data = await this.$api.get(`/WorkerReferrals/get`, {
          params: {
            jsonQuery: JSON.stringify(filterMongo),
            page: this.page,
            limit: this.limit,
          },
        })
        this.pagesLength = data?.pagesLength || 0
        this.workerReferralsLength = data?.count || 0
        this.workerReferrals = data?.result || []
      } catch (err) {
        // console.log(err);
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.isLoading = false
    },
  },
}
</script>
